<template>
  <div class="px-2 py-4">
    <h1 class="title">Prueba de PGA</h1>
    <b-message type="is-info">
      Califique de 0 a 5 el valor que mas se aproxime a su evaluación
    </b-message>

    <div class="block">
      <span>
        1. Eritema
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.PGA_P1"
            :native-value="answers.PGA_P1.PGA_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.PGA_P1"
            :native-value="answers.PGA_P1.PGA_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.PGA_P1"
            :native-value="answers.PGA_P1.PGA_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.PGA_P1"
            :native-value="answers.PGA_P1.PGA_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.PGA_P1"
            :native-value="answers.PGA_P1.PGA_P1_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.PGA_P1"
            :native-value="answers.PGA_P1.PGA_P1_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. Endurecimiento
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.PGA_P2"
            :native-value="answers.PGA_P2.PGA_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.PGA_P2"
            :native-value="answers.PGA_P2.PGA_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.PGA_P2"
            :native-value="answers.PGA_P2.PGA_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.PGA_P2"
            :native-value="answers.PGA_P2.PGA_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.PGA_P2"
            :native-value="answers.PGA_P2.PGA_P2_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.PGA_P2"
            :native-value="answers.PGA_P2.PGA_P2_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. Descamación
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.PGA_P3"
            :native-value="answers.PGA_P3.PGA_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            0
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.PGA_P3"
            :native-value="answers.PGA_P3.PGA_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.PGA_P3"
            :native-value="answers.PGA_P3.PGA_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.PGA_P3"
            :native-value="answers.PGA_P3.PGA_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.PGA_P3"
            :native-value="answers.PGA_P3.PGA_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.PGA_P3"
            :native-value="answers.PGA_P3.PGA_P3_R6"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5
          </b-radio>
        </div>
      </div>
    </div>
    <br />
    <div class="block has-text-centered title">
      Porcentaje de PGA/Resultado: {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>
    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { PGA as questions } from "../../../data/questions";
import { PGA as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "PgaList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
    }

    if (!this.$route.params.surveyId && !this.$route.params.temporalData) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      let calculate =
        parseInt(this.answerOne || 0) +
        parseInt(this.answerTwo || 0) +
        parseInt(this.answerThree || 0);
      return Math.round(calculate / 3 || 0);
    },
    isIncompleted() {
      return (
        this.answerOne === "" ||
        this.answerTwo === "" ||
        this.answerThree === ""
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta PGA.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "PGA",
          result: String(this.score),
          appreciation: "",
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;

      Object.keys(this.answers[questions.PGA_P1]).forEach((key) => {
        if (this.answers[questions.PGA_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.PGA_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.PGA_P2]).forEach((key) => {
        if (this.answers[questions.PGA_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.PGA_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.PGA_P3]).forEach((key) => {
        if (this.answers[questions.PGA_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.PGA_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });
      return [question1, question2, question3];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "PGA",
          route: "Pga",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "PGA_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "PGA_P2") {
          this.answerTwo = survey.value;
        }
        if (survey.question.code == "PGA_P3") {
          this.answerThree = survey.value;
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.PGA_P1.PGA_P1_R1;
      this.answerTwo = this.answers.PGA_P2.PGA_P2_R1;
      this.answerThree = this.answers.PGA_P3.PGA_P3_R1;
    },
  },
};
</script>
